import {getLinkPagoOtroSaldoFromApi} from "@/model/caratula/caratula.repository";


export const getLinkPagoOtroSaldo = (numero_caratula, id_tipo_pago) =>{
    return new Promise((resolve, reject) => {
        getLinkPagoOtroSaldoFromApi(numero_caratula, id_tipo_pago).then((response) => {
            if (response.data.status) {
                resolve(response.data.obj);
            } else {
                reject(response.data.obj);
            }
        }).catch(function (error) {
            reject(error);
        });
    })
}